import React from "react";
import { ChakraProvider } from "@chakra-ui/react";

import { CVScrollPage } from "./CVScrollPage";

const App = () => {
  return (
    <ChakraProvider>
      <CVScrollPage />
    </ChakraProvider>
  );
};

export default App;
